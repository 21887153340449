//播放视频的弹框
<template>
  <div class="play_video_dialog">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      width="1200px"
    >
      <template v-slot:content>
        <div class="play_video_content">
          <div class="player">
            <!-- 引入视频播放组件 -->
            <video-player
              class="video-player vjs-custom-skin"
              ref="video_m_player"
              :playsinline="true"
              :options="playerOptions"
            ></video-player>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
//引入混入文件
import video from "../../mixin/video";
export default {
  mixins: [video],
  data() {
    return {
      //宽度
      width: this.dialogWidth,

      //是否显示确定按钮加载框的标识
      showCommitLoading: false,

      //播放地址:
      videoUrl: "",

      //视频的封面
      postUrl: "",
    };
  },
  created() {
    //初始化配置下视频播放器
    this.initVideoPlayerOptions();
  },
  methods: {
    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },

    //配置播放器对象
    initVideoPlayerOptions() {
      this.playerOptions.sources[0].src = this.playObj.work_file;
      this.playerOptions.poster = this.playObj.work_cover;
      //动态设置播放器的宽高比
      // this.playerOptions.aspectRatio = "100:47";
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title", "playObj"],
};
</script>
<style lang="scss" scoped>
.play_video_dialog {
  ::v-deep .el-dialog {
    padding: 0;
  }
  ::v-deep .el-dialog {
    top: 56%;
  }
  ::v-deep .top .com_title {
    color: white;
  }
  ::v-deep .top .cloud-guanbi {
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  ::v-deep .el-dialog__body {
    background: black;
  }
  .play_video_content {
    display: flex;
    height: 585px;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 14px;
    flex-direction: column;

    .player {
      width: 100%;
      height: 560px;
      ::v-deep .vjs-poster {
        background-size: cover;
      }
      // ::v-deep video {
      //   width: 100% !important;
      //   height: 100%;
      //   object-fit: fill; //消除两边留白
      // }
    }
  }
}
</style>