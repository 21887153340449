//播放项目的弹框
<template>
  <div class="play_project_dialog">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="projectInfo.title"
      width="1000px"
    >
      <template v-slot:content>
        <div class="play_project_content">
          <!-- iframe组件,用于播放项目 -->
          <iframe
            allowFullScreen="true"
            :src="playUrl"
            class="if_project"
            frameborder="0"
          ></iframe>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
export default {
  data() {
    return {
      //项目播放的地址
      playUrl: "",
    };
  },
  created() {
    //初始化项目播放的地址
    this.initPlayUrl();
  },
  methods: {
    //初始化项目播放的地址
    initPlayUrl() {
      this.playUrl = this.$api.scratch3PlayUrl + this.projectInfo.projectId;
      console.log(this.playUrl)
    },
    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog", "projectInfo"],
};
</script>
<style lang="scss" scoped>
.play_project_dialog {
  ::v-deep .el-dialog {
    padding: 0;
  }
  ::v-deep .el-dialog {
    top: 50%;
  }
  ::v-deep .top .com_title {
    color: white;
  }
  ::v-deep .top .cloud-guanbi {
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  ::v-deep .el-dialog__body {
    background: black;
    // background: $common_bg;
  }
  .play_project_content {
    display: flex;
    height: 665px;
    align-items: center;
    justify-content: center;
    background: white;
    font-size: 14px;
    flex-direction: column;
    .if_project {
      width: 100%;
      height: 100%;
      // background: red;
    }
  }
}
</style>